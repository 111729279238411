import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import Barcode from 'react-barcode';
import { SUFFIX_LABNUMBER_TYPES } from '@/module/all-screenings/constants';

import MinmedLogo from '@/assets/image/minmed-logo.png';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { generateCurrentLabNumber } from '@/module/all-screenings/utils';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  },
  text: {
    fontSize: 18,
    fontFamily: 'Lato',
    fontWeight: 700
  }
});

const useStyles = makeStyles({
  formWrapper: {
    backgroundImage: `url(${require('../../assets/svg/form-lab-number.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 247,
    width: '100%'
  },
  labNumberText: {
    marginTop: 8,
    marginBottom: 4,

    '&:first-child': {
      marginTop: 16
    }
  },
  headerConsentForm: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#AB5998',
    height: 60
  },
  bodyConsentForm: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,

    padding: 0.5,
    backgroundColor: '#ffffff'
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.text}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles(theme => ({
  paper: {
    borderRadius: 16
  },
  paperWidthMD: {
    maxWidth: 640
  }
}))(Dialog);

const CustomButton = withStyles({
  root: {
    width: 192,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset'
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogContent = withStyles(theme => ({
  root: {
    padding: 16,
    textAlign: 'center',
    backgroundColor: '#E1E1E1',
    maxHeight: 420,

    '& .main-content': {
      marginTop: 30,
      fontSize: 32,
      fontWeight: 600
    },
    '& .sub-content': {
      fontSize: 16,
      color: '#666666'
    },
    '&:first-child': {
      paddingTop: theme.spacing(5)
    }
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    display: 'flex',
    gap: 8,
    padding: 16,
    justifyContent: 'center'
  }
})(DialogActions);

const ModalPrintLabNumber = ({
  open,
  onClose,
  onPrint,
  dataInfor,
  currentLabNumber,
  currentLabProvider
}) => {
  const classes = useStyles();

  const newCurrentLabNumber = useMemo(() => {
    return generateCurrentLabNumber(currentLabNumber, currentLabProvider);
  }, [currentLabNumber, currentLabProvider]);

  return (
    <CustomDialog
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>Lab number</CustomDialogTitle>
      <CustomDialogContent dividers>
        <Box width="100%">
          <Box className={classes.headerConsentForm}>
            <img
              id="minmed-logo"
              src={MinmedLogo}
              width="120"
              alt="minmed-logo"
            />
          </Box>
          <Box className={classes.bodyConsentForm}>
            <Typography className={classes.labNumberText}>
              {moment(dataInfor.healthScreeningDate)
                .utc()
                .format('DD/MM/YYYY')}
            </Typography>
            <Typography className={classes.labNumberText}>
              {dataInfor.fullName}
            </Typography>
            <Typography className={classes.labNumberText}>{`${
              dataInfor.identityNumber
            } - ${moment(dataInfor.dateOfBirth).format('DD MMM YYYY')} - ${
              dataInfor.gender
            }`}</Typography>
            <Barcode
              value={newCurrentLabNumber}
              width={2.5}
              height={46}
              font="Lato"
              fontOptions="bold"
            />
          </Box>
        </Box>
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton onClick={onPrint} variant="outlined" color="primary">
          Print
        </CustomButton>
        <CustomButton
          onClick={() => onPrint(SUFFIX_LABNUMBER_TYPES.SE_GF_SUFFIX)}
          variant="outlined"
          color="primary"
        >
          Print Suffix
        </CustomButton>
        <CustomButton
          onClick={() => onPrint(SUFFIX_LABNUMBER_TYPES.UP_SUFFIX)}
          variant="outlined"
          color="primary"
        >
          Print Suffix UP
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalPrintLabNumber;
