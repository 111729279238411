import ModalEditAcuity from './ModalEditTestAcuity';
import ModalEditTestBlood from './ModalEditTestBlood';
// Modal content
import ModalEditTestIBM from './ModalEditTestIBM';
import ModalEditTestPupilReflex from './ModalEditTestPupilReflex';
import ModalEditTestTonometry from './ModalEditTestTonometry';
import ModalEditTestUrineDipstick from './ModalEditTestUrineDipstick';
import ModalEditTestWaist from './ModalEditTestWaist';
// Schema validation
import validateData from '@/helpers/validationHelpers/validationSchema';
import { CHECKED_TYPE, SERVICE_TYPES } from '@/module/all-screenings/constants';
import CustomTooltip from '@/new-components/CustomTooltip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ErrorOutline } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { lowerCase } from 'lodash';
import React, { useState, useEffect } from 'react';

const titleStyles = (theme) => ({
  root: {
    display: 'flex',
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.grey[500],
    marginBottom: 'auto',
    marginLeft: 'auto',
    padding: 0,
  },
  title: {
    fontFamily: 'Lato',
    fontWeight: 700,
  },
});

export const getErrorMessage = (message) => {
  if (!message) {
    return null;
  }

  return (
    <>
      <ErrorOutline />
      {message}
    </>
  );
};

export const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset',
    },
  },
})(FormHelperText);

export const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    '& input': {
      fontSize: 16,
      marginLeft: 0,
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
        color: '#333333',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e1e1e1',
      },
    },
  },
})(TextField);

const CustomDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles((theme) => ({
  paper: {
    borderRadius: 16,
  },
  paperWidthXs: {
    maxWidth: 400,
  },
}))(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
    '&.red': {
      background: '#DB3D49',
    },
  },
  containedPrimary: {
    boxShadow: 'none',
  },
})(Button);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: '24px 16px',
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: '16px',
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderLeft: '1px solid #E1E1E1',
      borderRadius: 8,
    },
    '&:first-child': {
      borderRadius: 8,
    },
  },
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles((theme) => ({
  root: {
    flex: 1,
    border: '1px solid #E1E1E1',
    color: '#3333333',
    textTransform: 'unset',
    fontSize: 16,

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light,
    },
    '&.error': {
      border: `1px solid ${theme.palette.feedback.error}`,
    },
  },
}))(ToggleButton);

const CustomTypography = withStyles({
  root: {
    color: '#333333',
    marginBottom: 8,
  },
})(Typography);

const ModalInputValue = ({
  open,
  onClose,
  confirmLabel = 'Submit',
  onSubmitMeasurement,
  onSubmitTest,
  selectedItem,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [isMeasurementType, setIsMeasurementType] = useState(
    selectedItem.category === 'Measurements'
  );

  const isFatCode = selectedItem?.testCode === 'FAT';

  const [errors, setErrors] = useState({});
  const [checkedType, setCheckedType] = useState(null);
  const [formValue, setFormValue] = useState({});
  const [testValue, setTestValue] = useState('');

  useEffect(() => {
    let newFormValue = {};
    switch (lowerCase(selectedItem.testCode)) {
      case SERVICE_TYPES.BMI:
        const { height, weight } = selectedItem;
        newFormValue = { height: height.testValue, weight: weight.testValue };
        break;
      case SERVICE_TYPES.ACUITY:
        const {
          leftAided = {},
          leftUnaided = {},
          rightAided = {},
          rightUnaided = {},
        } = selectedItem;

        if (leftAided.visualAucityType === CHECKED_TYPE.aided) {
          setCheckedType(0);
        } else if (leftAided.visualAucityType === CHECKED_TYPE.unAided) {
          setCheckedType(1);
        } else {
          setCheckedType(null);
        }

        newFormValue = {
          leftAided: leftAided.testValue || '',
          leftUnaided: leftUnaided.testValue || '',
          rightAided: rightAided.testValue || '',
          rightUnaided: rightUnaided.testValue || '',
        };
        break;
      case SERVICE_TYPES.BLOOD_PRESSURE:
        const { diastolic, systolic, diastolic2, systolic2 } = selectedItem;
        newFormValue = {
          diastolic: diastolic.testValue,
          systolic: systolic.testValue,
          diastolic2: diastolic2?.testValue,
          systolic2: systolic2?.testValue,
        };
        break;
      case SERVICE_TYPES.URINE_DIPSTICK:
        const {
          urineDipstickBlood,
          urineDipstickGlucose,
          urineDipstickProtein,
        } = selectedItem;

        newFormValue = {
          urineDipstickBlood: lowerCase(urineDipstickBlood.testValue),
          urineDipstickGlucose: lowerCase(urineDipstickGlucose.testValue),
          urineDipstickProtein: lowerCase(urineDipstickProtein.testValue),
        };

        break;
      case SERVICE_TYPES.WAIST_HIP_RADIO:
        const { waist, hip } = selectedItem;
        newFormValue = { ratioWaist: waist.testValue, ratioHip: hip.testValue };
        break;
      case SERVICE_TYPES.APR:
      case SERVICE_TYPES.PVT:
        const { left, right } = selectedItem;
        newFormValue = {
          left: lowerCase(left.testValue),
          right: lowerCase(right.testValue),
        };
        break;
      case SERVICE_TYPES.EMF:
      case SERVICE_TYPES.ISHIHARA:
        break;
      case SERVICE_TYPES.TONOMETRY: {
        const { left, right } = selectedItem;
        newFormValue = {
          left: lowerCase(left.testValue),
          right: lowerCase(right.testValue),
        };
        break;
      }
      default:
        setIsMeasurementType(false);
        setTestValue(lowerCase(selectedItem?.testValue));
        break;
    }
    setFormValue(newFormValue);
  }, [selectedItem]);

  const onSubmitForm = () => {
    switch (lowerCase(selectedItem.testCode)) {
      case SERVICE_TYPES.BMI:
        handleSubmitBMI();
        break;
      case SERVICE_TYPES.ACUITY:
        handleSubmitAcuity();
        break;
      case SERVICE_TYPES.BLOOD_PRESSURE:
        handleSubmitBlood();
        break;
      case SERVICE_TYPES.URINE_DIPSTICK:
        handleSubmitUrineDipstick();
        break;
      case SERVICE_TYPES.WAIST_HIP_RADIO:
        handleSubmitWaist();
        break;
      case SERVICE_TYPES.APR:
      case SERVICE_TYPES.PVT:
        handleSubmitPupilReflex();
        break;
      case SERVICE_TYPES.TONOMETRY:
        handleSubmitTonometry();
        break;
      default:
        handleSubmitTest();
        break;
    }
  };

  const handleSubmitBMI = async () => {
    try {
      setBtnLoading(true);
      await validateData('testBMISchema', formValue, (data) => {
        setBtnLoading(false);
        const newIBMData = { ...selectedItem };
        newIBMData.height.testValue = data.height;
        newIBMData.weight.testValue = data.weight;
        onSubmitMeasurement(newIBMData, (isSuccess) => {
          if (isSuccess) onClose();
        });
      });
    } catch (errs) {
      setBtnLoading(false);
      setErrors(errs);
    }
  };

  const handleSubmitAcuity = async () => {
    try {
      setBtnLoading(true);

      await validateData(
        'testAcuitySchemaForScreeningProgress',
        { ...formValue, checkedType },
        (data) => {
          setBtnLoading(false);
          const newData = { ...selectedItem };
          let { leftAided, rightAided, leftUnaided, rightUnaided } = newData;
          leftAided.testValue = data.leftAided;
          rightAided.testValue = data.rightAided;
          leftUnaided.testValue = data.leftUnaided;
          rightUnaided.testValue = data.rightUnaided;
          leftAided.visualAucityType =
            checkedType === 0 ? CHECKED_TYPE.aided : CHECKED_TYPE.unAided;
          rightAided.visualAucityType =
            checkedType === 0 ? CHECKED_TYPE.aided : CHECKED_TYPE.unAided;
          leftUnaided.visualAucityType =
            checkedType === 0 ? CHECKED_TYPE.aided : CHECKED_TYPE.unAided;
          rightUnaided.visualAucityType =
            checkedType === 0 ? CHECKED_TYPE.aided : CHECKED_TYPE.unAided;
          if (checkedType === 0) {
            newData.visualAucityType = CHECKED_TYPE.aided;
          } else {
            newData.visualAucityType = CHECKED_TYPE.unAided;
          }
          newData.total = [leftAided, rightAided, leftUnaided, rightUnaided];

          onSubmitMeasurement(newData, (isSuccess) => {
            if (isSuccess) onClose();
          });
        }
      );
    } catch (errs) {
      setBtnLoading(false);
      setErrors(errs);
    }
  };

  const handleSubmitBlood = async () => {
    try {
      setBtnLoading(true);
      await validateData('testBloodSchema', formValue, (data) => {
        setBtnLoading(false);
        const newData = { ...selectedItem };
        newData.diastolic.testValue = data.diastolic;
        newData.systolic.testValue = data.systolic;
        newData.diastolic2.testValue = data.diastolic2;
        newData.systolic2.testValue = data.systolic2;

        onSubmitMeasurement(newData, (isSuccess) => {
          if (isSuccess) onClose();
        });
      });
    } catch (errs) {
      setBtnLoading(false);
      setErrors(errs);
    }
  };

  const handleSubmitUrineDipstick = async () => {
    try {
      setBtnLoading(true);

      await validateData('testUrineDipstickSchema', formValue, (data) => {
        setBtnLoading(false);
        const newData = { ...selectedItem };
        newData.urineDipstickBlood.testValue = data?.urineDipstickBlood;
        newData.urineDipstickGlucose.testValue = data?.urineDipstickGlucose;
        newData.urineDipstickProtein.testValue = data?.urineDipstickProtein;

        onSubmitMeasurement(newData, (isSuccess) => {
          if (isSuccess) onClose();
        });
      });
    } catch (errs) {
      setBtnLoading(false);
      setErrors(errs);
    }
  };

  const handleSubmitWaist = async () => {
    try {
      setBtnLoading(true);
      await validateData('testWaistSchema', formValue, (data) => {
        setBtnLoading(false);
        const newWaistRatio = { ...selectedItem };
        newWaistRatio.waist.testValue = data.ratioWaist;
        newWaistRatio.hip.testValue = data.ratioHip;
        onSubmitMeasurement(newWaistRatio, (isSuccess) => {
          if (isSuccess) onClose();
        });
      });
    } catch (errs) {
      setBtnLoading(false);
      setErrors(errs);
    }
  };

  const handleSubmitTest = async () => {
    try {
      setBtnLoading(true);
      const newTestData = { testValue };
      const schema = isFatCode ? 'testFatAnalysisSchema' : 'testValueSchema';

      await validateData(schema, newTestData, (data) => {
        setBtnLoading(false);
        onSubmitTest(data.testValue, (isSuccess) => {
          if (isSuccess) onClose();
        });
      });
    } catch (errs) {
      setBtnLoading(false);
      setErrors(errs);
    }
  };

  const handleSubmitPupilReflex = async () => {
    try {
      setBtnLoading(true);

      await validateData('testPublicReflexSchema', formValue, (data) => {
        setBtnLoading(false);
        const newData = { ...selectedItem };
        newData.left.testValue = data?.left;
        newData.right.testValue = data?.right;

        onSubmitMeasurement(newData, (isSuccess) => {
          if (isSuccess) onClose();
        });
      });
    } catch (errs) {
      setBtnLoading(false);
      setErrors(errs);
    }
  };

  const handleSubmitTonometry = async () => {
    try {
      setBtnLoading(true);
      await validateData('testTonometrySchema', formValue, (data) => {
        setBtnLoading(false);
        const newTonometryData = { ...selectedItem };
        newTonometryData.left.testValue = data.left;
        newTonometryData.right.testValue = data.right;
        onSubmitMeasurement(newTonometryData, (isSuccess) => {
          if (isSuccess) onClose();
        });
      });
    } catch (errs) {
      setBtnLoading(false);
      setErrors(errs);
    }
  };

  const handleChangeToggleValue = (newValue) => setTestValue(newValue);

  const handleChangeTestValue = (e) => {
    let value = e.target.value;
    setTestValue(value);
  };

  const renderModalContent = (testCode) => {
    let dialogContent = (
      <Grid item xs={12}>
        <CustomTypography variant="subtitle2">Value</CustomTypography>
        <CustomTextField
          size="small"
          fullWidth
          variant="outlined"
          placeholder="Enter value"
          value={testValue}
          onChange={handleChangeTestValue}
          error={!!errors.testValue}
          helperText={getErrorMessage(errors.testValue)}
        />
      </Grid>
    );

    switch (lowerCase(testCode)) {
      case SERVICE_TYPES.BMI:
        dialogContent = (
          <ModalEditTestIBM
            formValue={formValue}
            setFormValue={setFormValue}
            errors={errors}
          />
        );
        break;
      case SERVICE_TYPES.ACUITY:
        dialogContent = (
          <ModalEditAcuity
            formValue={formValue}
            setFormValue={setFormValue}
            checkedType={checkedType}
            setCheckedType={setCheckedType}
            errors={errors}
            setErrors={setErrors}
          />
        );
        break;
      case SERVICE_TYPES.BLOOD_PRESSURE:
        dialogContent = (
          <ModalEditTestBlood
            formValue={formValue}
            setFormValue={setFormValue}
            errors={errors}
          />
        );
        break;
      case SERVICE_TYPES.URINE_DIPSTICK:
        dialogContent = (
          <ModalEditTestUrineDipstick
            formValue={formValue}
            setFormValue={setFormValue}
            errors={errors}
          />
        );
        break;

      case SERVICE_TYPES.WAIST_HIP_RADIO:
        dialogContent = (
          <ModalEditTestWaist
            formValue={formValue}
            setFormValue={setFormValue}
            errors={errors}
          />
        );
        break;
      case SERVICE_TYPES.APR:
        dialogContent = (
          <ModalEditTestPupilReflex
            isAPR
            formValue={formValue}
            setFormValue={setFormValue}
            errors={errors}
          />
        );
        break;
      case SERVICE_TYPES.PVT:
        dialogContent = (
          <ModalEditTestPupilReflex
            formValue={formValue}
            setFormValue={setFormValue}
            errors={errors}
          />
        );
        break;
      case SERVICE_TYPES.EMF:
      case SERVICE_TYPES.ISHIHARA:
        dialogContent = (
          <Grid item xs={12}>
            <CustomToggleButtonGroup
              exclusive
              value={testValue}
              onChange={(e, newValue) => handleChangeToggleValue(newValue)}
            >
              <CustomToggleButton
                value="normal"
                className={errors.testValue ? 'error' : ''}
              >
                Normal
              </CustomToggleButton>
              <CustomToggleButton
                value="abnormal"
                className={errors.testValue ? 'error' : ''}
              >
                Abnormal
              </CustomToggleButton>
            </CustomToggleButtonGroup>
            {errors.testValue && (
              <CustomFormHelperText error>
                {getErrorMessage(errors.testValue)}
              </CustomFormHelperText>
            )}
          </Grid>
        );
        break;
      case SERVICE_TYPES.TONOMETRY:
        dialogContent = (
          <ModalEditTestTonometry
            formValue={formValue}
            setFormValue={setFormValue}
            errors={errors}
          />
        );
        break;
      default:
        break;
    }

    return dialogContent;
  };
  return (
    <CustomDialog
      maxWidth={isMeasurementType ? 'sm' : 'xs'}
      fullWidth
      scroll="body"
      disableEnforceFocus
      disableBackdropClick
      open={open}
      onClose={() => {
        onClose();
        setErrors({});
      }}
    >
      <CustomDialogTitle
        onClose={() => {
          onClose();
          setErrors({});
        }}
      >
        <CustomTooltip content={selectedItem?.testName} />
      </CustomDialogTitle>

      <CustomDialogContent dividers>
        <Grid container spacing={2}>
          {renderModalContent(selectedItem?.testCode)}
        </Grid>
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmitForm}
          disabled={btnLoading}
        >
          {confirmLabel}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalInputValue;
