import React from 'react';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  MincAdmin,
  DocumentIcon,
  CheckmarkIcon,
  Add,
  ReloadIcon,
  EditIcon
} from '@/assets/svg';
import { getNationalNumber } from '@/helpers';

import CustomTooltip from '@/new-components/CustomTooltip';
import { Drawer, IconButton, Tooltip } from '@material-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import ModalShowViewAll from './ModalShowViewAll';
import { HS_PAXSCREENING_STATUS } from '../constants';
import { isEmpty } from 'lodash';
import { isBillableProject } from '@/module/setup/utils';
import { LAB_NUMBERS_MODES } from '@/new-components/CustomModal/ModalAssignLabNumber';
import { generateTotalLabNumbers } from '../utils';

const useStyles = makeStyles({
  border: {
    height: 1,
    width: '100%',
    margin: '8px 0',

    backgroundImage: 'linear-gradient(to right, #C1C1C1 50%, transparent 50%)',
    backgroundPosition: 'top',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x'
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20
  },
  documentTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  totalCostWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    color: '#AD5E99',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  iconSurvey: {
    width: 40,
    height: 40,
    borderRadius: 12,
    backgroundColor: '#ffffff'
  },
  content: {
    margin: 0
  }
});

const rowStyles = makeStyles({
  title: {
    paddingBottom: 10,
    minWidth: 116,
    verticalAlign: 'top',
    fontWeight: 400,
    color: '#666666'
  },
  text: {
    paddingBottom: 10,
    fontSize: 16,
    verticalAlign: 'top'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666',
    width: 24,
    height: 24
  }
});

const iconSurveyStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: 40,
    height: 40,
    borderRadius: 12,
    backgroundColor: '#ffffff'
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: 24,
    height: 24,
    borderRadius: 12
  }
});

const CustomCard = withStyles({
  root: {
    padding: 20,
    boxShadow: 'none',
    borderRadius: 16,
    overflow: 'auto',
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset')
  }
})(Card);

const CustomButton = withStyles({
  root: {
    minWidth: 'unset',
    height: 20
  }
})(Button);

const RowHeaderItem = ({ title, icon, renderButton }) => {
  const Icon = icon;
  const classes = rowStyles();
  return (
    <th colSpan={3} className={classes.title} style={{ fontSize: 20 }}>
      <th
        colSpan={2}
        className={classes.title}
        style={{ fontSize: 20, fontWeight: 500 }}
      >
        {icon && <Icon className={classes.icon} />}
        <span style={{ verticalAlign: 'middle', color: '#333333' }}>
          {title}
        </span>
      </th>
      <td style={{ verticalAlign: 'middle' }}>
        {renderButton && renderButton()}
      </td>
    </th>
  );
};

const RowItem = ({ children, title }) => {
  const classes = rowStyles();

  if (typeof children === 'object') {
    return (
      <tr>
        <td className={classes.title}>{title}</td>
        <td className={classes.text}>{children}</td>
      </tr>
    );
  }

  return (
    <tr>
      <td className={classes.title}>{title}</td>
      {children === 'None' ? (
        <td className={classes.text} style={{ color: '#999999' }}>
          {children}
        </td>
      ) : (
        <td className={classes.text}>
          <CustomTooltip content={children} />
        </td>
      )}
    </tr>
  );
};

const CustomIconCheckmark = ({ isDone }) => {
  const classes = iconSurveyStyles();

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.circle}
        style={{
          backgroundColor: isDone ? '#0B8E5E' : '#999999'
        }}
      >
        <CheckmarkIcon />
      </div>
    </div>
  );
};

const GeneralInformation = ({
  dataInfor,
  projectInfor,
  currentLabNumber,
  totalLabNumbers,
  onOpenEditStaffType,

  lifestyleSurvey,
  customSurvey,
  consetForms,
  isFollowUp,
  totalTestCodes,
  isAfterRegistration,
  setShowAssignLabNumber,
  setLabNumbersMode,
  staffType,
  onAssignLabNumber
}) => {
  const classes = useStyles();
  const [showViewAll, setShowViewAll] = useState(false);

  const renderTooltipData = (data, maxLength = 25) => {
    const lstTotalData = [...data];

    const strData = data.join(', ');

    let shortStrData = '';
    if (strData && strData.length >= maxLength) {
      shortStrData = (lstTotalData[0] + ', ' + lstTotalData[1] || '') + ',...';
    }

    return shortStrData ? (
      <Tooltip
        title={
          <p style={{ color: '#fff', margin: 0, fontSize: 16 }}> {strData}</p>
        }
      >
        <p className={classes.content}> {shortStrData}</p>
      </Tooltip>
    ) : (
      <p className={classes.content}> {strData}</p>
    );
  };

  const isCompleted =
    dataInfor?.paxScreenStatus === HS_PAXSCREENING_STATUS.Completed;

  return (
    <>
      <CustomCard color="#e2f2ffb3" width={360}>
        <table style={{ width: '100%' }}>
          <RowHeaderItem title="Patient info" icon={MincAdmin} />
          <RowItem title="Name">{dataInfor?.fullName || 'None'}</RowItem>
          <RowItem title="ID number">
            {dataInfor?.identityNumber || 'None'}
          </RowItem>
          <RowItem title="Date of birth">
            {dataInfor?.dateOfBirth
              ? moment(dataInfor?.dateOfBirth).format('DD/MM/YYYY')
              : 'None'}
          </RowItem>
          <RowItem title="Ethnic group">
            {dataInfor?.ethnicGroup || 'None'}
          </RowItem>
          <RowItem title="Phone number">
            {dataInfor?.mobile
              ? parsePhoneNumber(
                  getNationalNumber(
                    dataInfor?.mobile,
                    dataInfor?.countryCode,
                    true
                  )
                ).formatInternational()
              : 'None'}
          </RowItem>
          <RowItem title="Email">{dataInfor?.email || 'None'}</RowItem>
          <RowItem title="Company">
            {projectInfor?.companyName || 'None'}
          </RowItem>
          <RowItem title="Staff type">
            <Box display="flex" justifyContent="space-between" width="100%">
              {staffType ? (
                <>
                  <CustomTooltip content={staffType.type} />
                  <IconButton
                    color="primary"
                    style={{ padding: 0 }}
                    onClick={onOpenEditStaffType}
                    hidden={isAfterRegistration}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              ) : (
                '—'
              )}
            </Box>
          </RowItem>
          <RowItem title="Test codes">
            {!isEmpty(totalTestCodes)
              ? renderTooltipData(totalTestCodes, 20)
              : 'None'}
          </RowItem>
          {isFollowUp ? null : (
            <RowItem title="PM code">{dataInfor?.pmCode || 'None'}</RowItem>
          )}
          {isFollowUp ? null : (
            <RowItem title="Lab number">
              {currentLabNumber ? (
                <Box display="flex" justifyContent="space-between" width="100%">
                  {renderTooltipData(generateTotalLabNumbers(totalLabNumbers))}
                  <Box>
                    <IconButton
                      color="primary"
                      style={{ padding: 0 }}
                      onClick={() => {
                        if (isCompleted) {
                          setLabNumbersMode(LAB_NUMBERS_MODES.ManualEdit);
                          setShowAssignLabNumber(true);
                        } else {
                          onAssignLabNumber(projectInfor?.labProvider);
                          setLabNumbersMode(LAB_NUMBERS_MODES.AutoEdit);
                        }
                      }}
                    >
                      <ReloadIcon />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <CustomButton
                  size="small"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => {
                    // if (isCompleted) {
                    //   setLabNumbersMode(LAB_NUMBERS_MODES.ManualAssign);
                    // } else {
                    //   setLabNumbersMode(LAB_NUMBERS_MODES.AutoAssign);
                    // }
                    // setShowAssignLabNumber(true);
                    onAssignLabNumber(projectInfor?.labProvider);
                  }}
                >
                  Assign
                </CustomButton>
              )}
            </RowItem>
          )}
        </table>
        <div className={classes.border}></div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.documentTitleWrapper}>
            <Typography className={classes.title}>
              <DocumentIcon style={{ marginRight: 8 }} />
              Documents
            </Typography>
            <CustomButton
              style={{ color: '#3776AB' }}
              onClick={() => setShowViewAll(!showViewAll)}
            >
              View all
            </CustomButton>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" gridGap={8}>
              <CustomIconCheckmark isDone={dataInfor?.isSignedConsentForm} />
              Consent Form
            </Box>
          </Grid>
          {/* <Grid item xs={12}>
            <Box display="flex" alignItems="center" gridGap={8}>
              <CustomIconCheckmark
                isDone={dataInfor?.isCompletedCustomSurvey}
              />
              Custom survey
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" gridGap={8}>
              <CustomIconCheckmark isDone={dataInfor?.isCompletedSurvey} />
              Lifestyle survey
            </Box>
          </Grid>
        </Grid>
      </CustomCard>
      {showViewAll && (
        <Drawer
          anchor="right"
          open={showViewAll}
          onClose={() => setShowViewAll(false)}
          disableBackdropClick
          PaperProps={{
            style: {
              width: '50%',
              minWidth: 768
            }
          }}
        >
          <ModalShowViewAll
            dataInfor={dataInfor}
            lifestyleSurvey={lifestyleSurvey}
            customSurvey={customSurvey}
            consetForms={consetForms}
            onClose={() => {
              setShowViewAll(false);
            }}
          />
        </Drawer>
      )}
    </>
  );
};

export default GeneralInformation;
