import { urlLabel } from './enum/PermissionEnum';
import fetchHelper from './helpers/FetchHelper';
import AppBridge from './module/app-bridge';
import IdleTimeOutHandler from './module/idleTime';
import VerifyPatient from './module/verify-patient/index';
import { WrapperAuthRoute } from './routers/WrapperAuthRoute';
import { WrapperPrivateRoute } from './routers/WrapperPrivateRoute';
import { WrapperRoute } from './routers/WrapperRoute';
import authRoutes from './routers/authRoute';
import privaterouters from './routers/privateRoute';
import React from 'react';
import Progress from 'react-progress-2';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

fetchHelper.addBeforeRequestInterceptor(() => Progress.show());
fetchHelper.addAfterResonseInterceptor(() => Progress.hide());

const App = () => {
  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        hideProgressBar
        rtl={false}
        draggable={false}
      />
      <Wrapper>
        <Progress.Component />
      </Wrapper>
      <Router>
        <IdleTimeOutHandler />

        <Switch>
          <WrapperRoute path={`/${urlLabel.deepLink}`} component={AppBridge} />
          <WrapperRoute
            path={`/${urlLabel.verifyPatient}`}
            component={VerifyPatient}
          />
          {authRoutes.map(
            ({
              path,
              component: Component,
              exact,
              publicComponent = false
            }) => (
              <WrapperAuthRoute
                path={path}
                component={Component}
                exact={exact}
                publicComponent={publicComponent}
              />
            )
          )}

          {privaterouters.map(({ path, component: Component, exact }) => (
            <WrapperPrivateRoute
              path={path}
              component={Component}
              exact={exact}
            />
          ))}

          <Route path={`/${urlLabel.excutePaypal}`}>
            <div></div>
          </Route>
          <WrapperRoute />
        </Switch>
      </Router>
    </div>
  );
};

const Wrapper = styled.div`
  .loader-60devs {
    z-index: 10051;
  }
  .loader-60devs .loader-60devs-progress {
    height: 0.2rem;
    width: 100%;
    background-color: #128ff9;
  }
  /* comment below if you want react-progress-2 to block UI */
  .loader-60devs::before {
    display: none;
  }
`;

export default App;
